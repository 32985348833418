import {
	Extra,
	Menu,
	Order,
	OrderDeal,
	OrderDealMenuItem,
	OrderItem,
	OrderMenuItem,
	OrderProductVariation,
	OrderProductVariationExtras,
} from '@monorepo/upsell-kit';

import useResize from '../../../../lib/hooks/useResize';

interface Props {
	items: Order['items'];
	type?: string;
}

const OrderSummary = ({ items, type = 'default' }: Props) => {
	const { sm } = useResize();

	return (
		<div className="flex w-full flex-col gap-3.5">
			{items.map((item, i) => {
				const shouldRender = (sm && i < 2) || (!sm && i < 1) || type == 'default';
				return shouldRender ? (
					<OrderMenu item={item} key={i} />
				) : (
					<h3
						className={`text-xs font-bold sm:text-sm ${sm && i > 2 && '!hidden'} ${!sm && i > 1 && '!hidden'}`}
					>
						+{items.length - 1} more
					</h3>
				);
			})}
		</div>
	);
};

const OrderMenu = ({ item }: { item: OrderItem }) => {
	const isOrderDeal = (item: OrderItem) =>
		['fixed_deals', 'custom_deals', 'simple_deals'].includes(`${item.type}`);

	const isOrderHasExtra = (item: OrderItem) =>
		['variation_extras', 'simple_extras'].includes(`${item?.type}`);

	const isOrderVariation = (item: OrderItem) => ['variation'].includes(`${item?.type}`);

	return (
		<div className="flex w-full gap-3.5 ">
			<div className="flex !h-8 !w-8 items-center justify-center rounded-full bg-gray-100 text-xs font-medium text-gray-800">
				{item.quantity}x
			</div>

			<div
				className={`flex w-fit flex-grow flex-col ${isOrderDeal(item) ? 'justify-start' : 'justify-center'}`}
			>
				<div className="flex w-full items-start justify-between">
					<div className={`${isOrderDeal(item) ? '' : 'mb-0'}`}>
						<h3 className="whitespace flex text-sm font-bold leading-tight text-gray-800">
							{'name' in item ? item.name : 'Split product'}
						</h3>
					</div>
				</div>

				{isOrderDeal(item) && <SubMenuOrderDeal item={item as OrderDeal} />}

				{isOrderHasExtra(item) && (
					<SubMenuWithExtras item={item as OrderProductVariationExtras} />
				)}

				{isOrderVariation(item) && (
					<SubMenuWithVariation item={item as OrderProductVariation} />
				)}
			</div>
		</div>
	);
};

export default OrderSummary;

const SubMenuOrderDeal = ({ item }: { item: OrderDeal }) => {
	const isDealHasNoSplit = (
		menu_items: OrderMenuItem | undefined,
	): menu_items is OrderDealMenuItem[] => {
		return (
			menu_items !== undefined &&
			Array.isArray(menu_items) &&
			menu_items.length > 0 &&
			'name' in menu_items[0]
		);
	};
	return (
		<div className="mt-1 flex flex-wrap">
			{item.menus.map((menu: Menu, index: number) => {
				const menu_data = menu.menu_items;

				return (
					<div className="flex-cc flex flex-row" key={index}>
						<span className="text-xs">
							{isDealHasNoSplit(menu_data) &&
								`${menu_data[0].name} ${menu_data[0].quantity}x`}
						</span>
						{index + 1 !== item.menus.length && (
							<div className="mx-2 h-1 w-1 rounded-md bg-gray-200"></div>
						)}
					</div>
				);
			})}
		</div>
	);
};

const SubMenuWithExtras = ({ item }: { item: OrderProductVariationExtras }) => {
	return (
		<div className="mt-1 flex flex-wrap ">
			{item.extras.map((extra: Extra, index: number) => (
				<div className="flex-cc flex flex-row " key={index}>
					<span className="text-xs">{`${extra.name}`}</span>
					{index + 1 !== item.extras.length && (
						<div className=" mx-2 h-1 w-1 rounded-md bg-gray-200"></div>
					)}
				</div>
			))}
		</div>
	);
};

const SubMenuWithVariation = ({ item }: { item: OrderProductVariation }) => {
	return (
		<div className="mt-1 flex flex-wrap ">
			<div className="flex-cc flex flex-row ">
				<span className="text-xs">{`${item.variation.name}`}</span>
			</div>
		</div>
	);
};
