'use client';

// Prismic
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

// import { useState } from 'react';
// Upsell
import upsell from '@monorepo/upsell-kit';

import Animate from '../../components/Animation/Animate';
import CardMenuSkelton from '../../components/menu/cards/CardMenuSkelton';
// Component
import Deals from '../../components/menu/cards/Deals';

/**
 * Props for `DealSection`.
 */
export type DealSectionProps = SliceComponentProps<Content.DealSectionSlice>;

/**
 * Component for "DealSection" Slices.
 */
const DealSection = (): JSX.Element => {
	// Get the data
	const { data: dealsData } = upsell.data.useDeals();

	// const [isShowMore, setIsShowMore] = useState(false);

	return (
		<div className="flex w-full flex-col justify-center gap-7 bg-white md:gap-0 md:bg-theme-white-dark ">
			{/* Title */}
			<Animate className="flex px-5 pt-6 text-lg font-bold leading-none text-primary md:hidden ">
				<h3 className="animate">Tilboð</h3>
			</Animate>
			{/* Item deals section */}
			<div className="flex-cc col px-5 pb-16 lg:py-[140px]">
				<div className="flex-ss mb-12 flex w-full max-w-[1280px]">
					<Animate
						type="stack"
						delay={0.3}
						friction={0.25}
						className="grid w-full grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 lg:gap-6"
					>
						{/* Deals Card */}
						{dealsData && dealsData.length > 0 ? (
							<>
								{dealsData?.slice(0, 4).map((item, j) => {
									// Render the Deals component for non-product items
									return (
										<div className="animate up h-full w-full flex-1" key={j}>
											<Deals deal={item} fullHeight />
										</div>
									);
								})}
								{/* {isShowMore &&
									dealsData?.slice(4).map((item, j) => {
										// Render the Deals component for non-product items
										return (
											<div className="animate up w-full" key={j}>
												<Deals deal={item} />
											</div>
										);
									})} */}
							</>
						) : (
							// Skelton section
							<>
								{[1, 2, 3, 4].map((_, i) => (
									<div className="animate up w-full" key={i}>
										<CardMenuSkelton />
									</div>
								))}
							</>
						)}
					</Animate>
				</div>
				{/* <div className="flex-cc w-full">
					{isShowMore ? (
						<button
							onClick={() => setIsShowMore(false)}
							className="flex-cc block rounded-full bg-theme-green px-5 py-3 font-medium uppercase text-white transition-colors hover:bg-theme-green-dark"
						>
							Sýna minna.
						</button>
					) : (
						<button
							onClick={() => setIsShowMore(true)}
							className="flex-cc block rounded-full bg-theme-green px-5 py-3 font-medium uppercase text-white transition-colors hover:bg-theme-green-dark"
						>
							Sýna meira.
						</button>
					)}
				</div> */}
			</div>
		</div>
	);
};

export default DealSection;
