import React from 'react';

const AppendDots = (dots: React.ReactNode) => {
	return (
		<div>
			<ul className="translate-y-1 lg:-mt-[80px] lg:translate-y-0 [&>li.slick-active>button]:!bg-theme-green [&>li.slick-active>button]:lg:!bg-white [&>li>button]:!bg-[#F0F0F0] [&>li>button]:lg:!bg-[#F0F0F0]/60 [&>li]:!h-auto [&>li]:!w-auto [&>li]:!overflow-hidden [&>li]:!rounded-full">
				{dots}
			</ul>
		</div>
	);
};

export default AppendDots;
