import React from 'react';

type Props = {
	progress: number;
	radius?: number;
	stroke?: number;
	color?: string;
	children?: React.ReactNode;
};

const CircleProgress = ({
	progress,
	radius = 15,
	stroke = 2.5,
	color = '#008744',
	children,
}: Props) => {
	const normalizedRadius = radius - stroke * 2;
	const offset = normalizedRadius + stroke;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	return (
		<div
			className="relative flex items-center justify-center"
			style={{ height: offset * 2, width: offset * 2 }}
		>
			<svg height={offset * 2} width={offset * 2}>
				<circle
					stroke="#EFEFEF"
					fill="transparent"
					strokeWidth={stroke}
					r={normalizedRadius}
					cx={offset}
					cy={offset}
				/>
				<circle
					stroke={color}
					fill="transparent"
					strokeWidth={stroke}
					strokeDasharray={circumference + ' ' + circumference}
					style={{ strokeDashoffset }}
					strokeLinecap="round"
					r={normalizedRadius}
					cx={offset}
					cy={offset}
					transform={`rotate(-90 ${offset} ${offset})`}
				/>
			</svg>
			<div className="absolute flex items-center justify-center">{children}</div>
		</div>
	);
};

export default CircleProgress;
