import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { ReactNode, useEffect, useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);

interface AnimateWrapperProps {
	children: ReactNode;
	className?: string;
	delay?: number;
	duration?: number;
	friction?: number;
	type?: 'single' | 'stack';
	stackDelay?: number;
	animate?: boolean; // New prop to control animation
}

const Animate: React.FC<AnimateWrapperProps> = ({
	children,
	className,
	delay = 0,
	duration = 0.8,
	friction = 0,
	type = 'single',
	stackDelay = 0.2,
	animate = true, // Default to true to keep current behavior
}) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	const animateFrom = (elems: HTMLElement[], direction: number, delay: number): void => {
		elems.forEach((elem) => {
			let x = 0,
				y = 0;

			if (elem.classList.contains('right')) {
				x = -100;
				y = 0;
			} else if (elem.classList.contains('left')) {
				x = 100;
				y = 0;
			} else if (elem.classList.contains('up')) {
				x = 0;
				y = 100 * direction;
			} else if (elem.classList.contains('down')) {
				x = 0;
				y = -100 * direction;
			}

			elem.style.transform = `translate(${x}px, ${y}px)`;
			elem.style.opacity = '0';

			gsap.fromTo(
				elem,
				{ x, y, autoAlpha: 0 },
				{
					duration: duration,
					x: 0,
					y: 0,
					autoAlpha: 1,
					delay: delay,
					ease: 'expo',
					overwrite: 'auto',
					clearProps: 'x,y,visibility',
				},
			);
		});
	};

	const hide = (elems: HTMLElement[]): void => {
		gsap.set(elems, { autoAlpha: 0 });
	};

	useEffect(() => {
		if (!animate) {
			return; // Do not run animations if animate is false
		}

		const container = containerRef.current;
		if (!container) {
			return;
		}

		const elements = Array.from(
			container.querySelectorAll(':scope > .animate'),
		) as HTMLElement[];
		hide(elements);

		const startPercentage = friction * 100;
		const endPercentage = 100 - startPercentage;

		const startTrigger = `top ${endPercentage}%`;
		const endTrigger = `bottom ${startPercentage}%`;

		ScrollTrigger.batch(elements, {
			start: startTrigger,
			end: endTrigger,
			onEnter: (batch) => {
				let adjustedDelay = 0;
				if (type === 'single') {
					adjustedDelay = delay;
					animateFrom(batch as HTMLElement[], 1, adjustedDelay);
				} else {
					batch.forEach((elem, i) => {
						adjustedDelay = delay + i * stackDelay;
						animateFrom([elem as HTMLElement], 1, adjustedDelay);
					});
				}
			},
			once: true,
		});
	}, [delay, duration, friction, type, stackDelay, animate]);

	return (
		<div className={className} ref={containerRef}>
			{children}
		</div>
	);
};

export default Animate;
