'use client';

// Prismic
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
// 	Next
import Image from 'next/image';
import { useRouter } from 'next/navigation';

// Upsell-kit
import upsell from '@monorepo/upsell-kit';

// Aniamte
import Animate from '../../components/Animation/Animate';
// Animation
// List product categories
import { MENU_SETUP } from '../../constants/upsell';
// Utils
import { normalizeWord } from '../../lib/normalizeWord';

/**
 * Props for `MenuListMobile`.
 */
export type MenuListMobileProps = SliceComponentProps<Content.MenuListMobileSlice>;

/**
 * Component for "MenuListMobile" Slices.
 */

// Props for all menu data (Include deals and products)
export type menuDataType = {
	_id: string;
	key: string;
	type: string;
	name: string;
	image: string;
	isLoading: boolean;
};

const MenuListMobile = ({ slice }: MenuListMobileProps): JSX.Element => {
	// Router for navigation
	const router = useRouter();

	// Filter product data based on tue MENU_SETUP
	const productsData = MENU_SETUP.map((categoryID) => {
		const { data: categoryData, isLoading: isCategoryLoading } =
			upsell.data.useCategoryByID(categoryID);
		const { data: products, isLoading: isProductsLoading } =
			upsell.data.useProductsByCategory(categoryID);

		return {
			_id: categoryData?._id || '',
			key: normalizeWord(categoryData?.name || ''),
			type: 'product',
			name: categoryData?.name || '',
			image: categoryData?.image?.url || '',
			isLoading: !products || isCategoryLoading || isProductsLoading,
		};
	});

	// declare deals data
	const dealsData = {
		_id: '1',
		key: normalizeWord('Tilbod'),
		type: 'deal',
		name: 'Tilbod',
		image: '/images/tilbod_icon.png', // Updated path
		isLoading: false,
	};

	// Join the dealsData and productsData in one array
	const menusData: menuDataType[] = [dealsData, ...productsData];

	// Check isLoadaing of the data
	const isLoading = menusData.some((section) => section.isLoading === true);

	return (
		<div className=" flex-cs flex w-full border-b-4 border-theme-white-dark px-5 py-6  lg:hidden">
			{!isLoading ? (
				// Menu list section
				<div className="flex w-full flex-col gap-6">
					{/* Heading */}
					<Animate duration={1} className=" text-lg font-bold leading-none">
						<h3 className="animate">{slice.primary.headig}</h3>
					</Animate>
					{/* List */}
					<Animate delay={0.2} className="grid grid-cols-4 gap-4">
						{/* Menus data */}
						{menusData.map((data, index) => (
							<div
								onClick={() => router.push(`/menu#${data.key}`)}
								key={index}
								className="flex-cc animate up flex cursor-pointer flex-col gap-2"
							>
								<div className="flex rounded-full bg-theme-green bg-opacity-10 p-2">
									<div className="relative h-10 w-10">
										<Image
											src={data.image}
											alt={`thumbnail for ${data.name}`}
											layout="fill"
											objectFit="contain"
										/>
									</div>
								</div>
								<p className="text-xs font-medium">{data.name}</p>
							</div>
						))}
					</Animate>
				</div>
			) : (
				// Skelton section
				<div className="flex w-full flex-col gap-6">
					<div className="h-6 w-24  animate-pulse rounded-md bg-gray-200"></div>
					<div className="grid grid-cols-4 gap-4">
						{menusData.map((data, index) => (
							<div key={index} className="flex-cc flex flex-col gap-2">
								<div className="flex h-10 w-10 animate-pulse rounded-full bg-gray-200"></div>
								<div className=" h-4 w-10 animate-pulse rounded-md  bg-gray-200"></div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MenuListMobile;
