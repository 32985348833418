'use client';

// Prismic
import { Content, isFilled } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
// Next
import Image from 'next/image';
import Link from 'next/link';
// React
import React, { useState } from 'react';
// Slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Animate from '../../components/Animation/Animate';
import { useLayout } from '../../contexts';
// Hooks
import useResize from '../../lib/hooks/useResize';
// Component
import AppendDots from './components/AppendDots';
import CustomPaging from './components/CustomPaging';
import NavArrow from './components/NavArrow';

/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice }: HeroProps): JSX.Element => {
	// Currnet active slide
	const [activeSlide, setActiveSlide] = useState<number>(0);
	const { topBannerActive } = useLayout();

	// Detect screensize
	const screenSize = useResize();

	// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
	const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

	const triplet = (e1: number, e2: number, e3: number) =>
		keyStr.charAt(e1 >> 2) +
		keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
		keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
		keyStr.charAt(e3 & 63);

	const rgbDataURL = (r: number, g: number, b: number) =>
		`data:image/gif;base64,R0lGODlhAQABAPAA${
			triplet(0, r, g) + triplet(b, 255, 255)
		}/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

	return (
		<section
			data-slice-type={slice.slice_type}
			data-slice-variation={slice.variation}
			className={[
				'md:pt-42 w-full overflow-hidden bg-white pt-52 lg:bg-theme-green',
				topBannerActive ? 'lg:pt-12' : 'lg:pt-0',
			].join(' ')}
		>
			<div className="mx-auto w-[87%] pb-9 lg:w-full lg:pb-0">
				{/* Slider section */}
				<Slider
					infinite
					speed={1000}
					slidesToShow={1}
					slidesToScroll={1}
					lazyLoad={screenSize.width > 1023 ? 'ondemand' : undefined}
					arrows={true}
					autoplay
					className="relative h-[200px] md:h-[400px] lg:h-[calc(100vh-100px)] [&_.slick-list]:-lg:!overflow-visible"
					dots
					appendDots={AppendDots}
					customPaging={CustomPaging}
					prevArrow={<NavArrow direction="left" />}
					nextArrow={<NavArrow direction="right" />}
					beforeChange={(_, next) => setActiveSlide(next)}
				>
					{/* Slider Item */}
					{slice.items.map((item, index) => {
						return (
							<Link
								href={isFilled.keyText(item.cta_link) ? item.cta_link : '#'}
								key={index}
								className={`  h-[200px] w-full transition-all md:h-[400px] lg:h-[calc(100vh-100px)] ${activeSlide !== index ? 'scale-[.92] lg:scale-100' : ''}`}
							>
								<Animate
									duration={1.5}
									className="h-full w-full overflow-hidden rounded-[10px] lg:rounded-none"
								>
									{/* Image Desktop version */}
									<div className=" animate relative flex h-full w-full opacity-0">
										{screenSize.width > 1023 && (
											<Image
												src={`${item.slide_image_desktop.url}`}
												layout="fill"
												objectFit="cover"
												alt="slide_image_desktop"
												quality={20}
												placeholder="blur"
												blurDataURL={rgbDataURL(237, 181, 6)}
												unoptimized
												priority
											/>
										)}

										{/* Image Mobile Version*/}
										{screenSize.width < 1024 && (
											<Image
												src={`${item.slide_image_mobile.url}`}
												layout="fill"
												objectFit="cover"
												alt="slide_image_mobile"
												quality={20}
												placeholder="blur"
												blurDataURL={rgbDataURL(237, 181, 6)}
												unoptimized
												priority
											/>
										)}
									</div>
								</Animate>
							</Link>
						);
					})}
				</Slider>
				{/* Progerss slider bar */}
				<div className=" hidden h-[9px] w-full bg-theme-green lg:flex ">
					<div
						style={{
							width: `${((activeSlide + 1) / slice.items.length) * 100}%`,
							transition: 'width 0.5s ease',
						}}
						className="absolute left-0 flex h-[9px] bg-theme-yellow"
					></div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
