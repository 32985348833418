import React from 'react';

export default function CardMenuSkelton() {
	return (
		<div className="flex-bc col relative h-full w-full cursor-pointer overflow-hidden rounded-xl  border border-gray-200 bg-white hover:shadow-lg">
			<div className="flex-cc col w-full">
				<div className=" flex-cc  relative h-[115px] w-full animate-pulse overflow-hidden bg-gray-200 md:h-[225px]"></div>

				<div className="flex w-full flex-col gap-2 p-3 md:p-4 lg:gap-2.5">
					<div className="flex-bs w-full">
						<div className="h-4 w-1/2  animate-pulse bg-gray-200"></div>
					</div>
					<div className="flex-sc  w-full text-gray-400">
						<div className="h-4  w-full  animate-pulse bg-gray-200 "></div>
					</div>
					<div className="h-4 w-full  animate-pulse bg-gray-200 "></div>
				</div>
			</div>
		</div>
	);
}
