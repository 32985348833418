'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import upsell from '@monorepo/upsell-kit';

import Animate from '../../components/Animation/Animate';
import Button from '../../components/_shared/Button';
import OrderSummary from '../../components/profile/MyOrders/ActiveOrdersCard/OrderSummary';
import OrderHeader from '../../components/profile/MyOrders/HistoryOrdersCard/OrderHeader';

/**
 * Props for `OrderHistory`.
 */
export type OrderHistoryProps = SliceComponentProps<Content.OrderHistorySlice>;

/**
 * Component for "OrderHistory" Slices.
 */
const OrderHistory = ({ slice }: OrderHistoryProps): JSX.Element => {
	const router = useRouter();
	const { data: historyOrders, isLoading: historyLoading } = upsell.data.useOrdersHistory();

	const isOrdersAvailable = historyOrders && historyOrders.length > 0;

	return (
		<div className="flex-cc flex ">
			<div className="flex w-full">
				{isOrdersAvailable && (
					<>
						{historyLoading ? (
							<div>Loading</div>
						) : (
							<div className=" flex w-full flex-col items-center gap-7 px-0 py-10 md:px-0 md:pb-20 md:pt-16">
								<Animate className=" flex-bc flex w-full justify-between px-5 text-base lg:px-[13%] ">
									<div className=" animate right text-lg font-bold text-black md:text-[32px]">
										{slice.primary.title}
									</div>

									<Button
										className="animate left !w-fit !px-6 !py-2.5 !font-medium"
										theme="default"
										onClick={() => {
											router.push(`${slice.primary.cta_link}`);
										}}
									>{`${slice.primary.cta_text}`}</Button>
								</Animate>

								<div className="flex w-full ">
									<Animate
										type="stack"
										className="md:flex-ss no-scrollbar flex w-fit flex-row gap-4 !overflow-x-scroll md:!w-full md:gap-4 "
									>
										{historyOrders.map((order, i) => {
											return (
												<Link
													href={`/profile/order/${order._id}`}
													className={`animate up h-full ${i == 0 && ' ml-5 lg:ml-[13%]'} ${i + 1 == historyOrders.length && ' mr-5 lg:mr-[13%]'}`}
												>
													<div
														key={i}
														className={`  m-0 flex h-full !w-full min-w-[300px] flex-col rounded-xl border border-gray-200 bg-white md:!w-full md:!min-w-[400px] md:max-w-[500px] `}
													>
														<div className="flex w-full border-b border-gray-200 px-3.5 py-4 lg:px-6 lg:py-5">
															<OrderHeader order={order} />
														</div>
														<div className="flex w-full flex-col px-3.5 py-4 lg:px-6 lg:py-5">
															<OrderSummary
																items={order.items}
																type="slice"
															/>
														</div>
													</div>
												</Link>
											);
										})}
									</Animate>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default OrderHistory;
