'use client';

// Prismic
import { Content } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from '@prismicio/react';
// Next
import { useRouter } from 'next/navigation';

import Animate from '../../components/Animation/Animate';
import Button from '../../components/_shared/Button';
import useResize from '../../lib/hooks/useResize';

/**
 * Props for `StoreBanner`.
 */
export type StoreBannerProps = SliceComponentProps<Content.StoreBannerSlice>;

/**
 * Component for "StoreBanner" Slices.
 */
const StoreBanner = ({ slice }: StoreBannerProps): JSX.Element => {
	// Router for navigation
	const router = useRouter();
	// Screen Size for check the width is exceed or not
	const screenSize = useResize();

	return (
		// Menus section
		<div
			className="flex-cc md:order flex w-full  bg-theme-white-dark px-5 py-10  lg:py-[150px] "
			data-slice-type={slice.slice_type}
			data-slice-variation={slice.variation}
		>
			{screenSize.width !== 0 && (
				<Animate
					friction={0.25}
					className=" flex-bc flex w-full overflow-hidden rounded-xl  lg:max-w-[1280px] "
				>
					<div className="animate flex w-full flex-row  border-none border-gray-400 bg-theme-yellow shadow-none md:shadow-lg lg:bg-white">
						{/* First section */}
						<Animate
							delay={0.4}
							friction={0.25}
							className="flex-bs lg:flex-cs w-1/2 flex-col gap-10 py-4 pl-4 lg:pl-12"
						>
							<div className="animate right flex flex-col gap-2">
								<PrismicRichText
									components={{
										heading1: ({ children }) => (
											<h1 className=" text-dark text-base font-bold leading-none text-white lg:mb-2 lg:text-3xl lg:text-black">
												{children}
											</h1>
										),
									}}
									field={slice.primary.title}
								/>
								<PrismicRichText
									components={{
										paragraph: ({ children }) => (
											<p className="animate left text-xs text-white lg:text-base lg:text-gray-600">
												{children}
											</p>
										),
									}}
									field={slice.primary.description}
								/>
							</div>
							<Button
								type="button"
								onClick={() => router.push('/')}
								theme={screenSize.width < 1024 ? 'white' : 'default'}
								className="animate up !w-auto !px-4 !py-2 !text-xs !font-medium hover:!text-theme-green lg:!px-8 lg:!py-4 lg:!text-base "
							>{`${slice.primary.cta_text}`}</Button>
						</Animate>
						{/* Second section */}

						<div className="flex w-1/2">
							<div className="flex-cc lg:flex-ee  relative flex w-full flex-col">
								<PrismicNextImage
									field={
										screenSize.width < 1024
											? slice.items[1].store_image
											: slice.items[0].store_image
									}
									className="absolute -bottom-10 -right-8  lg:relative lg:right-0 lg:top-0"
								/>
							</div>
						</div>
					</div>
				</Animate>
			)}
		</div>
	);
};

export default StoreBanner;
