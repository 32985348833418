// React
import React from 'react';
// React Slick
import { CustomArrowProps } from 'react-slick';

// Animation
import Animate from '../../../components/Animation/Animate';

// Props
interface IconProps {
	className?: string;
	direction?: 'left' | 'right';
}

// Nav main component
const NavArrow = (props: CustomArrowProps & { direction: IconProps['direction'] }) => (
	// Start animate
	<Animate
		className={`lg:flex-cc absolute top-1/2 z-[2] hidden h-16 w-16 -translate-y-1/2 transition-all  active:scale-90 ${props.direction === 'left' ? 'left-20' : 'right-20'}`}
	>
		{/* Animation direction based on the props.direction */}
		<button
			className={`animate ${props.direction == 'left' ? 'right' : 'left'}  flex-cc h-full w-full rounded-full bg-white opacity-0 hover:bg-gray-200`}
			onClick={props.onClick}
			aria-label="navigation arrow"
		>
			<NavArrowIcon direction={props.direction} />
		</button>
	</Animate>
);

// Nav Icon
const NavArrowIcon = ({ className = '', direction = 'left' }: IconProps) => (
	<svg
		width="34"
		height="34"
		viewBox="0 0 34 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={`${className} ${direction === 'right' ? 'rotate-180' : ''}`}
	>
		<path
			d="M13.5573 26.6618C13.8264 26.6618 14.0956 26.5627 14.3081 26.3502C14.7189 25.9393 14.7189 25.2593 14.3081 24.8485L6.45975 17.0002L14.3081 9.15183C14.7189 8.741 14.7189 8.061 14.3081 7.65017C13.8973 7.23933 13.2173 7.23933 12.8064 7.65017L4.20725 16.2493C3.79642 16.6602 3.79642 17.3402 4.20725 17.751L12.8064 26.3502C13.0189 26.5627 13.2881 26.6618 13.5573 26.6618Z"
			fill="#008744"
		/>
		<path
			d="M5.199 18.0625H29.0415C29.6223 18.0625 30.104 17.5808 30.104 17C30.104 16.4192 29.6223 15.9375 29.0415 15.9375H5.199C4.61817 15.9375 4.1365 16.4192 4.1365 17C4.1365 17.5808 4.61817 18.0625 5.199 18.0625Z"
			fill="#008744"
		/>
	</svg>
);

export default NavArrow;
