'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import React, { useEffect, useState } from 'react';
import { IoChevronDown } from 'react-icons/io5';

import CustomDialog from '../../../components/_shared/CustomDialog';

/**
 * Props for `NutritionTableNavigation`.
 */
export type NutritionTableNavigationDefaultVariant = Extract<
	Content.NutritionTableSlice,
	{
		variation: 'nutritionTableNavigation';
	}
>;

/**
 * Component for "NutritionTableNavigation" Slices.
 */
const NutritionTableNavigation = ({
	slice,
}: SliceComponentProps<NutritionTableNavigationDefaultVariant>): JSX.Element => {
	const [elementIds, setElementIds] = useState<string[]>([]);
	const [activeId, setActiveId] = useState<string | null>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const scrollToHashWithOffset = (
		event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>,
		hash: string,
		offset = 80,
	) => {
		event.preventDefault();

		const targetElement = document.getElementById(hash.replace('#', ''));

		if (targetElement) {
			window.scrollTo({
				top: targetElement.offsetTop - offset,
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		const elements = document.getElementsByClassName('nutrition-table');
		const ids = Array.from(elements).map((element) => element.id);
		setElementIds(ids);

		const observerCallback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setActiveId(entry.target.id);
				}
			});
		};

		const observer = new IntersectionObserver(observerCallback, {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		});

		ids.forEach((id) => {
			const element = document.getElementById(id);
			if (element) {
				observer.observe(element);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<section
			data-slice-type={slice.slice_type}
			data-slice-variation={slice.variation}
			className="sticky top-0 border-b border-[#EBEBEB] bg-white py-2 md:top-20 md:py-6"
		>
			{/* Desktop Navigation */}
			<ul className="flex-cc container gap-10 font-medium uppercase -md:hidden">
				{elementIds.map((id) => (
					<li key={id}>
						<a
							href={`#${id}`}
							onClick={(event) => scrollToHashWithOffset(event, id)}
							className={`${activeId === id ? 'border-b-4 border-theme-green text-theme-green' : ''} whitespace-nowrap py-4 text-sm md:py-6 md:text-base md:text-primary`}
						>
							{id}
						</a>
					</li>
				))}
			</ul>

			{/* Mobile Navigation */}
			<div className="flex w-full border-gray-200 bg-white md:hidden">
				<div
					className="flex-bc flex w-full flex-row px-5 py-3"
					onClick={() => setIsDropdownOpen(true)}
				>
					<div className="flex-cc flex flex-row">
						<p className="mb-0 font-medium uppercase">{activeId}</p>
					</div>
					<div className={isDropdownOpen ? 'flex rotate-180' : 'flex'}>
						<IoChevronDown size={16} />
					</div>
				</div>
				<CustomDialog
					open={isDropdownOpen}
					handleClose={() => setIsDropdownOpen(false)}
					mobileOnly
				>
					<div className="flex-bc mb-6 flex w-full flex-row">
						<p className="flex-bc text-lg font-bold uppercase">Category</p>
					</div>
					<div className="flex flex-col gap-4">
						{elementIds.map((category, index) => {
							return (
								<>
									{category && (
										<div
											onClick={(event) => {
												scrollToHashWithOffset(event, `#${category}`);
												setIsDropdownOpen(false);
											}}
											key={index}
											className="flex cursor-pointer"
										>
											<p
												className={`${activeId === category ? ' text-green-500' : ' text-black'} text-sm font-medium uppercase`}
											>
												{category}
											</p>
										</div>
									)}
								</>
							);
						})}
					</div>
				</CustomDialog>
			</div>
		</section>
	);
};

export default NutritionTableNavigation;
