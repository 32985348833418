'use client';

// Prismic
import { Content } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
// Date
import { getDay } from 'date-fns';
// Next
import { useRouter } from 'next/navigation';
// React
import { useRef, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
// Slider
import Slider from 'react-slick';

// Animation
import Animate from '../../components/Animation/Animate';
// Component
import Button from '../../components/_shared/Button';
import AppendDots from './components/AppendDots';
import CustomPaging from './components/CustomPaging';

const dayMapper: Record<string, number> = {
	Sunnudagur: 0,
	Mánudagur: 1,
	Þriðjudagur: 2,
	Miðvikudagur: 3,
	Fimmtudagur: 4,
	Föstudagur: 5,
	Laugardagur: 6,
};

/**
 * Props for `FeaturedProduct`.
 */
export type FeaturedProductProps = SliceComponentProps<Content.FeaturedProductSlice>;

/**
 * Component for "FeaturedProduct" Slices.
 */
const FeaturedProduct = ({ slice }: FeaturedProductProps): JSX.Element => {
	// Save the active current slide index
	const [activeSlide, setActiveSlide] = useState<number>(0);

	// Router for navigation
	const router = useRouter();

	// Slick slider component ref
	const sliderRef = useRef<Slider>(null);
	const next = () => sliderRef.current?.slickNext();
	const previous = () => sliderRef.current?.slickPrev();

	// Get the current day
	const dayOfWeek = getDay(new Date());

	return (
		// Main content
		<div className="flex w-full flex-col">
			{/* Container with bg green light */}
			<section
				data-slice-type={slice.slice_type}
				data-slice-variation={slice.variation}
				className="flex-cc relative w-full overflow-hidden bg-theme-green pb-28 pt-10 lg:pb-32 lg:pt-32"
			>
				{/* Bg green dark */}
				<div className="absolute bottom-0 z-10 flex h-3/5 w-full bg-theme-green-dark lg:h-1/2"></div>
				{/* Day Background */}
				<h3 className="absolute top-[12.5%] z-[15] text-[250px] font-bold uppercase tracking-wider text-white opacity-15 -lg:text-[100px]">
					{slice.items[activeSlide].day_name?.substring(0, 3)}
				</h3>
				<div className="flex-cc relative z-20 flex h-full w-full flex-col ">
					{/* The header */}

					<Animate
						friction={0.25}
						className="mb-24 text-[24px] font-bold text-theme-yellow-text lg:mb-24 lg:text-[62px]"
					>
						<h3 className="animate down">{slice.primary.heading}</h3>
					</Animate>
					<div className="flex-cc mb-16 flex w-full flex-col lg:mb-24 lg:w-full">
						<Slider
							ref={sliderRef}
							infinite
							speed={1000}
							slidesToShow={1}
							slidesToScroll={1}
							lazyLoad={undefined}
							className="relative z-20 w-7/12 lg:w-1/2 [&_.slick-list]:!overflow-visible"
							dots
							appendDots={AppendDots}
							draggable
							customPaging={CustomPaging}
							arrows={false}
							beforeChange={(_, next) => setActiveSlide(next)}
						>
							{slice.items.map((item, index) => (
								<Animate
									key={index}
									duration={1}
									delay={0.3 * index}
									friction={0.25}
									className={`relative block transform transition-all duration-1000 ${activeSlide === index ? 'scale-[1.8] lg:scale-[1.1]' : ' scale-100 lg:scale-90'}`}
								>
									<PrismicNextImage
										key={index} // Adding a key prop is recommended when rendering a list of elements
										field={item.product_image}
										// Fix the style object
										className={`animate up m-0 h-full max-h-[70px] w-full cursor-grab object-contain object-center active:cursor-grabbing sm:max-h-[100px] md:max-h-[320px] `}
									/>
								</Animate>
							))}
						</Slider>
					</div>

					<div className="flex-cc">
						<button
							onClick={previous}
							className="flex-cc h-16 w-16 rounded-full border border-white bg-white bg-opacity-0 text-white hover:bg-opacity-100 hover:text-theme-green-dark"
							aria-label="left button"
						>
							<BsArrowLeft size={30} />
						</button>
						<div className="flex-cc flex min-w-[600px] flex-col">
							<Animate
								delay={0.3}
								friction={0.25}
								duration={1.2}
								className="flex-cc mb-5 flex w-full flex-col gap-1.5 lg:mb-6 lg:gap-3"
							>
								<p className=" animate right text-[15px] font-medium uppercase leading-none text-theme-yellow-text lg:text-[24px] lg:font-bold">
									{slice.items[activeSlide].day_name}
								</p>
								<h4 className="animate left text-[17px] font-bold uppercase text-white lg:text-[24px]">
									{slice.items[activeSlide].product_name}
								</h4>
							</Animate>

							{dayOfWeek === dayMapper[slice.items[activeSlide].day_name ?? 1] && ( // Default to monday
								// <Animate delay={0.3} friction={0.25} duration={1.2}>
								<Button
									onClick={() =>
										router.push(`${slice.items[activeSlide].cta_link}`)
									}
									theme="yellow"
									className="animate up !w-fit !px-6 !py-3 !text-sm !font-medium lg:!px-8 lg:!py-4 lg:!text-base"
								>{`${slice.items[activeSlide].cta_text}`}</Button>
								// </Animate>
							)}
						</div>
						<button
							onClick={next}
							className="flex-cc h-16 w-16 rounded-full border border-white bg-white bg-opacity-0 text-white hover:bg-opacity-100 hover:text-theme-green-dark"
							aria-label="right button"
						>
							<BsArrowRight size={30} />
						</button>
					</div>
				</div>
			</section>
			<div className=" hidden h-[9px] w-full bg-theme-green lg:flex ">
				<div
					style={{
						width: `${((activeSlide + 1) / slice.items.length) * 100}%`,
						transition: 'width 0.5s ease',
					}}
					className="absolute left-0 flex h-[9px] bg-theme-yellow"
				></div>
			</div>
		</div>
	);
};

export default FeaturedProduct;
