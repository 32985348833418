'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Deal, getPrimary, toCurrency } from '@monorepo/upsell-kit';

import { DEALS_WITH_DYNAMIC_PRICING_TEMPORAR } from '../../../../constants/deals';

// import ActionsArea from './ActionsArea';

interface Props {
	deal: Deal;
	fullHeight?: boolean;
}

const Deals = ({ deal, fullHeight }: Props) => {
	const thumbnail = getPrimary(deal.images);

	return (
		<Link
			href={`/deals/${deal._id}`}
			className={`animate up flex-bc col relative w-full cursor-pointer overflow-hidden rounded-xl  border border-gray-200 bg-white hover:shadow-lg ${fullHeight ? 'h-full' : ''}`}
		>
			<div className="flex-cc col w-full">
				{thumbnail && (
					<div className="flex-cc relative h-[115px] w-full overflow-hidden md:h-[225px]">
						<Image
							src={thumbnail.url}
							alt={`thumbnail for ${deal.name}`}
							layout="fill"
							objectFit="cover"
						/>
					</div>
				)}
				<div className="flex w-full flex-col gap-2 p-3 md:p-4 lg:gap-2.5">
					<div className="flex-bs w-full">
						<p className="text-xs font-bold uppercase !text-black md:text-base">
							{deal.name}
						</p>
					</div>
					<div className="text-gray-400">
						<p className="line-clamp-3 text-xs leading-5 text-secondary md:text-[13px]">
							{deal.description}
						</p>
					</div>
					{!DEALS_WITH_DYNAMIC_PRICING_TEMPORAR.includes(deal._id) && (
						<div className="text-xs font-medium md:text-sm">
							{toCurrency(deal.base_price)}
						</div>
					)}
				</div>
			</div>
			{/* <ActionsArea product={product} /> */}
		</Link>
	);
};

export default Deals;
