'use client';

// Prismic
import { Content } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
// Running text library
import Marquee from 'react-fast-marquee';

// Component Animation
import Animate from '../../components/Animation/Animate';

/**
 * Props for `RunningText`.
 */
export type RunningTextProps = SliceComponentProps<Content.RunningTextSlice>;

/**
 * Component for "RunningText" Slices.
 */
const RunningText = ({ slice }: RunningTextProps): JSX.Element => {
	return (
		// Animation
		<div
			className={`w-full overflow-hidden ${slice.primary.hide_on_mobile ? ' hidden md:flex' : 'flex '}`}
		>
			{/* Running text */}
			<Marquee gradient={false} speed={50} className="flex-grow">
				<Animate delay={0.1} className="flex min-w-full">
					{/* Multoly by 10 for the items to make it can fit to the page*/}
					{Array.from({ length: 10 })
						.flatMap(() => slice.items)
						.map((item, index) => (
							<div
								key={index}
								className="animate flex-cc mx-3 flex min-w-[100px] flex-row py-4 -md:mx-2"
							>
								{/* Image */}
								<PrismicNextImage
									field={item?.icon}
									className="mr-5 h-9 -md:mr-2 -md:h-6"
								/>
								{/* Title */}
								<h3
									style={{ color: `${item?.text_color}` }}
									className="text-[42px] font-bold -md:text-base"
								>
									{item.text}
								</h3>
							</div>
						))}
				</Animate>
			</Marquee>
		</div>
	);
};

export default RunningText;
