export const getOrderStatusText = (status: string) => {
	switch (status) {
		case 'order_received':
			return 'Pöntun móttekin';
		case 'processing':
			return 'Pöntunin bíður undirbúnings';
		case 'in_oven':
			return 'Í ofninum';
		case 'ready_to_pickup':
			return 'Við erum að undirbúa pöntunina';
		case 'ready_to_ship':
			return 'Beðið eftir sendli';
		case 'on_delivery':
			return 'Á leiðinni til þín';
		case 'delivered':
			return 'Matur er afhentur';
		case 'completed':
			return 'Pöntun er lokið';
		default:
			return '';
	}
};
