import React from 'react';

const AppendDots = (dots: React.ReactNode) => {
	return (
		<div>
			<ul className="absolute -bottom-[210px] left-1/2 w-full  -translate-x-1/2  lg:hidden [&>li.slick-active>button]:!bg-theme-white [&>li.slick-active>button]:!bg-opacity-100 [&>li.slick-active>button]:lg:!bg-white [&>li>button]:!bg-[#F0F0F0] [&>li>button]:!bg-opacity-50 [&>li>button]:lg:!bg-[#F0F0F0]/60 [&>li]:!h-2.5 [&>li]:!w-2.5 [&>li]:!overflow-hidden [&>li]:!rounded-full">
				{dots}
			</ul>
		</div>
	);
};

export default AppendDots;
