'use client';

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

type Props = {
	open: boolean;
	handleClose: () => void;
	mobileOnly?: boolean;
	children: ReactNode;
};

function CustomDialog({ open, handleClose, mobileOnly, children }: Props) {
	const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

	const isMobileScreen = screenWidth < 1023;

	if (mobileOnly && !isMobileScreen) {
		return null;
	}

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				as="div"
				className={['relative z-[100]', mobileOnly ? 'lg:hidden' : ''].join(' ')}
				onClose={handleClose}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-30" />
				</Transition.Child>

				<div className="fixed bottom-0 right-0 z-50 w-full">
					<div className="flex h-screen w-full items-end justify-center pt-6 text-center lg:items-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="translate-y-full opacity-0"
							enterTo="translate-y-0 opacity-100"
							leave="translate-y-0 ease-in duration-200"
							leaveFrom="translate-y-0 opacity-100"
							leaveTo="translate-y-full opacity-0"
						>
							<Dialog.Panel className="relative flex max-h-[80vh] w-full transform flex-col overflow-auto rounded-t-xl bg-white p-5 text-left align-middle shadow-[-4px_4px_20px_-1px_rgba(0,0,0,0.15)] transition-all lg:w-auto lg:rounded-xl">
								<div
									className="absolute right-0 top-0 cursor-pointer p-4"
									onClick={handleClose}
								>
									<FiX className="text-xl text-gray-500" />
								</div>
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
}

export default CustomDialog;
