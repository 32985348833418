'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { secondsToMinutes } from 'date-fns';
import Link from 'next/link';
import { FaCheck } from 'react-icons/fa6';
import { IoChevronForward } from 'react-icons/io5';

import upsell, { useOrderEstimation } from '@monorepo/upsell-kit';

import CircleProgress from '../../components/_shared/CircleProgress';
import { getOrderStatusText } from '../../lib/getOrderStatusText';

/**
 * Props for `OrderStatusPopup`.
 */
export type OrderStatusPopupProps = SliceComponentProps<Content.OrderStatusPopupSlice>;

/**
 * Component for "OrderStatusPopup" Slices.
 */
const OrderStatusPopup = ({ slice }: OrderStatusPopupProps): JSX.Element => {
	const { data: activeOrders } = upsell.data.useOrdersActive();
	const order = activeOrders?.[0];

	const data = order && 'data' in order ? order.data : null;

	const dynamic_waiting_time =
		data && 'dynamic_waiting_time' in data && (data.dynamic_waiting_time as number);
	const ready_at = data && 'ready_at' in data && (data.ready_at as string);

	const { estimatedTime, percentageElapsed, timeRemaining } = useOrderEstimation(
		order
			? {
					...order,
					createdAt: ready_at || order.createdAt,
					waiting_time: dynamic_waiting_time || order.waiting_time,
				}
			: undefined,
	);

	const radius = 35;
	const stroke = 5;

	if (!order) {
		return <></>;
	}

	return (
		<>
			{activeOrders && activeOrders.length > 0 && (
				<section
					className="relative h-0"
					data-slice-type={slice.slice_type}
					data-slice-variation={slice.variation}
				>
					<Link
						href={`/profile/order/${order._id}`}
						className="flex-bc fixed bottom-24 left-1/2 right-auto z-[100] flex w-[95%] -translate-x-1/2 flex-row gap-7 rounded-xl border border-gray-200 bg-white px-5 py-4  shadow-lg md:left-auto md:right-6 md:w-fit md:-translate-x-0 lg:bottom-10"
					>
						<div className="flex flex-row gap-3 lg:gap-4">
							<div className="flex items-start justify-start ">
								{order.order_status === 'processing' ? (
									<div className="spinning">
										<CircleProgress
											progress={50}
											radius={radius}
											stroke={stroke}
											color={'#D4D4D4'}
										/>
									</div>
								) : (
									<CircleProgress
										progress={percentageElapsed}
										radius={radius}
										stroke={stroke}
									>
										<div className="flex-cc absolute flex flex-col text-xl">
											{percentageElapsed !== 100 ? (
												<div className="flex-cc col absolute inset-0 h-full w-full">
													<p className="text-lg font-bold leading-none text-gray-700">
														{timeRemaining > 60
															? secondsToMinutes(timeRemaining)
															: timeRemaining}
													</p>
													<p className="text-xs font-medium leading-none text-gray-700">
														{timeRemaining > 60 ? 'mín' : 'sek'}
													</p>
												</div>
											) : (
												<FaCheck size={34} className="text-theme-green" />
											)}
										</div>
									</CircleProgress>
								)}
							</div>
							<div className="lg:flex-cs flex flex-col gap-2">
								<p className="text-base font-bold text-black lg:text-lg">
									{dynamic_waiting_time && percentageElapsed === 100
										? 'Tilbúið til afhendingar'
										: getOrderStatusText(order.order_status)}
								</p>
								{dynamic_waiting_time ? (
									percentageElapsed === 100 ? (
										<p className="w-fit text-xs text-gray-400 sm:text-sm "></p>
									) : (
										<p className="w-fit text-xs text-gray-400 sm:text-sm ">
											Áætlaður afhendingartími {`${estimatedTime}`}
										</p>
									)
								) : (
									<p className="w-fit text-xs text-gray-400 sm:text-sm ">
										Vinsamlegast bíddu
									</p>
								)}
							</div>
						</div>
						<div className="flex-cc flex cursor-pointer flex-row gap-2.5">
							<IoChevronForward size={24} />
						</div>
					</Link>
				</section>
			)}
		</>
	);
};

export default OrderStatusPopup;
